<template>
  <div
    class="companies bg-yellow flex flex-col items-center justify-center mt-8 self-center w-4/5 p-8"
  >
    <h2 class="font-bold text-xl">Buscar empresa</h2>
    <input
      type="text"
      class="py-2 px-4 rounded-lg my-4 bg-white"
      v-model="search"
    />

    <v-data-table
      :headers="headers"
      :items="companies"
      :items-per-page="10"
      class="w-full"
      :loading="loadingData"
      loading-text="Cargando empresas"
      :search="search"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <router-link
          :to="`/empresa/${item.id}`"
          class="bg-yellow text-white uppercase rounded-lg p-2 font-bold"
          >Ver más</router-link
        >
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      companies: [],
      headers: [
        {
          text: "Nombre",
          align: "start",
          value: "name",
        },
        { text: "NIT", value: "nit" },
        { text: "Tracksales", value: "cid" },
        { text: "Ciudad", value: "city" },
        { text: "", value: "actions", sortable: false },
      ],
      search: "",
      loadingData: true,
    };
  },
  async mounted() {
    const res = await this["companies/getCompanies"]();

    if (res.success) {
      this.companies = res.data;
    }

    this.loadingData = false;
  },
  methods: {
    ...mapActions(["companies/getCompanies"]),
  },
};
</script>

<style lang="scss" scoped>
.companies {
  a {
    color: white;
  }
}
</style>
